import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { receiveVendor } from '@redux/vendor';
import { useMedia } from '@xo-union/tk-ui-breakpoints';
import classNames from 'classnames';
import { useSetAtom } from 'jotai';
import React, { type FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import type { Gallery } from 'types/reviews';
import AnalyticsConstants from '../../../../../constants/analytics';
import { afterNextPaint } from '../../../../../helpers/after-next-paint';
import Vendor from '../../../../decorators/vendor';
import VendorAnalytics from '../../../../decorators/vendorAnalytics';
import { useStorefrontFeatureScannability } from '../../../../pages/Storefront/hooks/useStorefrontFeatureScannability';
import Styles from './Filmstrip.scss';
import { Thumbnail } from './thumbnail';
import { calculateDisplayCounter } from './utils';

type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
	gallery: Gallery[];
	profile?: Vendor.Raw;
};

export type FilmstripProps = StateProps & OwnProps;

const Filmstrip: FC<FilmstripProps> = (props) => {
	const { gallery, vendor, profile } = props;
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [additionalImageCount, setAdditionalImageCount] = useState(0);
	const [viewableImages, setViewableImages] = useState(0);
	const media = useMedia('(min-width: 1024px');
	const shouldShowScannability = useStorefrontFeatureScannability();

	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	const dispatch = useAppDispatch();
	const isReviewHub = useAppSelector(
		(state) => state.page.pageType === 'review wedding vendors',
	);
	const { track } = useAnalyticsContext();

	useEffect(() => {
		const runDisplayCounter = () => {
			calculateDisplayCounter({
				element: containerRef.current,
				gallery,
				isDesktop: media.yes,
				setAdditionalImageCount,
				setViewableImages,
			});
		};

		runDisplayCounter();

		window.addEventListener('resize', runDisplayCounter);

		return () => window.removeEventListener('resize', runDisplayCounter);
	}, [media.yes, gallery]);

	if (gallery.length === 0) {
		return null;
	}

	const handleTrack = () => {
		const selectedVendor = profile ? new Vendor(profile) : vendor;
		const vendorAnalytics = new VendorAnalytics(selectedVendor);
		const sourcePage =
			selectedVendor.purchaseStatus === 'PAID'
				? AnalyticsConstants.PAID_STOREFRONT
				: AnalyticsConstants.UNPAID_STOREFRONT;
		track({
			event: 'Review Interaction',
			properties: {
				...vendorAnalytics.baseEventProps(),
				action: 'view photo',
				portfolioType: AnalyticsConstants.portfolioType.REVIEWS,
				sourcePage,
				sourceContent: 'single review filmstrip',
				mediaCount: gallery.length,
			},
		});
	};

	const onClick = async (index: number) => {
		afterNextPaint(() => {
			const shouldPopulateVendor = isReviewHub && !!profile;
			if (shouldPopulateVendor) {
				dispatch(receiveVendor({ data: { storefront: profile } }));
			}

			handleTrack();

			const reviewMediaId = gallery[index].sourceId;
			setOpenUnifiedLightbox({
				indexInAllMedia: index,
				filter: 'reviews',
				reviewMediaId,
			});
			setIsUnifiedLightboxModalOpen(true);
		});
	};

	return (
		<>
			<div className={Styles.container} ref={containerRef}>
				{gallery.map(({ sourceId }, idx) => {
					if (idx + 1 < viewableImages) {
						return (
							<>
								<Thumbnail
									sourceId={sourceId}
									index={idx}
									key={sourceId}
									onClick={() => onClick(idx)}
								/>
								{isUnifiedLightboxModalOpen && (
									<UnifiedLightboxModal
										entryPoint="Reviews"
										isModalOpen={isUnifiedLightboxModalOpen}
										closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
									/>
								)}
							</>
						);
					}
					if (idx + 1 === viewableImages) {
						return (
							<>
								<Thumbnail
									sourceId={sourceId}
									index={idx}
									key={sourceId}
									onClick={() => onClick(idx)}
								>
									<div
										className={classNames(Styles.additionalImagesContainer, {
											[Styles.additionalImagesContainerHidden]:
												shouldShowScannability && gallery.length <= 3,
										})}
									>
										<span
											className={Styles.additionalImagesText}
										>{`+${additionalImageCount}`}</span>
									</div>
								</Thumbnail>
								{isUnifiedLightboxModalOpen && (
									<UnifiedLightboxModal
										entryPoint="Reviews"
										isModalOpen={isUnifiedLightboxModalOpen}
										closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
									/>
								)}
							</>
						);
					}
					return null;
				})}
			</div>
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<StateProps, object, OwnProps>(mapStateToProps)(
	Filmstrip,
);
